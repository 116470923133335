import React from "react";
// import AuthLayout from "../../components/";
import {
  Col,
  Row,
  Typography,
  List,
  Form,
  Layout,
  Input,
  Button,
  Card,
  Tabs,
  Table,
  Image,
  Divider,
  Rate,
} from "antd";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { Post } from "../../config/api/post";
import { AUTH, UPLOADS_URL } from "../../config/constants/api";
import { addUser, removeUser } from "../../redux/slice/authSlice";
import { PiMedal } from "react-icons/pi";
import {
  FaArrowRight,
  FaCheck,
  FaCheckCircle,
  FaMedal,
  FaRegClock,
} from "react-icons/fa";
import { TbBolt } from "react-icons/tb";
import { LiaBrainSolid } from "react-icons/lia";
import swal from "sweetalert";
import { ImageUrl } from "../../helpers/Function";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoIosMail } from "react-icons/io";
const { Meta } = Card;
// import router from "next/router";

function Homepage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { Search } = Input;
  const user = useSelector((state) => state.user.userData);
  const token = useSelector((state) => state.user.userToken);
  const [loading, setLoading] = React.useState(false);

  // useEffect if user is already logged in
  React.useEffect(() => {
    if (user && token) {
      navigate("/", { replace: true });
    }
  }, [user, token]);

  const onFinish = (values) => {
    console.log("Success:", values);
    setLoading(true);

    let data = {
      email: values.email,
      password: values.password,
      devideId: "123456789",
    };
    Post(AUTH.signin, data)
      .then((response) => {
        setLoading(false);
        if (response?.data) {
          console.log("response", response.data.token);
          console.log("response", response.data.user);
          dispatch(
            addUser({ user: response.data.user, token: response.data.token })
          );
          navigate("/", { replace: true });
        } else {
          swal("Oops!", response.response.data.message, "error");
        }
      })
      .catch((e) => {
        console.log(":::;", e);
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  //   const data = [
  //     {
  //       title: "Zoom.",
  //     },
  //     {
  //       title: "Microsoft Teams.",
  //     },
  //     {
  //       title: "GoToWebinar and GoToMeeting.",
  //     },
  //     {
  //       title: "Vimeo",
  //     },
  //   ];

  //   const data2 = [
  //     {
  //       title: "Assign an instructor to class.",
  //     },
  //     {
  //       title: "Choose class start and end times.",
  //     },
  //     {
  //       title: "Include online live classes inside of a self-paced course.",
  //     },
  //   ];

  //   const data3 = [
  //     {
  //       avatar: <GiHamburgerMenu />,
  //       title: "Track Enrollment Dates",
  //       des: "See what date a user enrolled in a course and when they last were active.",
  //     },
  //     {
  //       avatar: <FaRegClock />,
  //       title: "Report On Certifications",
  //       des: "Quickly identify who’s completed a course and received a certification.",
  //     },
  //     {
  //       avatar: <FaMedal />,
  //       title: "Offline Reporting",
  //       des: "Download reports to review offline with colleagues.",
  //     },
  //   ];

  const data2 = [
    {
      title: "Provide course access to users directly after purchase.",
    },
  ];

  const data3 = [
    {
      title: "Customers can access a courses directly after payment.",
    },
    {
      title: "Skip eCommerce and manually enroll users into courses.",
    },
  ];

  const data4 = [
    {
      title: "Create a course catalog, ‘about us’, checkout page, and more.",
    },
    {
      title:
        "Change website menus & pages, and customize your eCommerce store to best represent your company.",
    },
  ];

  //   const cardData = [
  //     {
  //       image: "new-6.png",
  //       title: "“Software with tons of features”",
  //       description:
  //         "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more.",
  //       name: "Adam P.",
  //       role: "Model Teaching",
  //     },
  //     {
  //       image: "new-7.png",
  //       title: "“The software is user-friendly...”",
  //       description:
  //         "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less norma.",
  //       name: "Karen B.",
  //       role: "1st Choice Real Estate",
  //     },
  //     {
  //       image: "new-8.png",
  //       title: "“Easy to use and learn...”",
  //       description:
  //         "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here",
  //       name: "Bryanna S.",
  //       role: "Diabetes Ed",
  //     },
  //   ];

  return (
    <Layout style={{ minHeight: "100vh", backgroundSize: "cover" }}>
      <section>
        <Row className="cource-banner" justify={"center"} align={"middle"}>
          <Col xs={24} md={10}>
            <div className="">
              <Row style={{ width: "100%", justifyContent: "center" }}>
                <Col xs={20} md={20} className="formWrap">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("miniImg3.png")}
                  />
                  <p className="text-p-14">Flexible eCommerce</p>
                  <Typography.Title
                    className="fontFamily1 smallTitle"
                    style={{ color: "#fff" }}
                  >
                    {<> Sell Your Courses & eLearning Online</>}
                  </Typography.Title>

                  <Typography.Text
                    className="fontFamily1 smallText"
                    style={{ color: "#fff" }}
                  >
                    Earn revenue from your courses and programs, by selling them
                    online with our customizable landing pages, or by connecting
                    them to your own eCommerce site.
                  </Typography.Text>
                  <br />
                  <br />
                  <br />
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="whiteButton"
                    onClick={() => navigate("/about-us")}
                    style={{ marginLeft: "5px" }}
                  >
                    Get Started
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="loginButton"
                    onClick={() => navigate("/about-us")}
                  >
                    See The Platform <FaArrowRight />
                  </Button>
                </Col>
              </Row>
            </div>
          </Col>

          <Col xs={0} sm={0} md={12}>
            <div className="" style={{ textAlign: "center" }}>
              <Row style={{ width: "100%", paddingRight: "20px" }} gutter={40}>
                <Col xs={0} sm={12} md={24}>
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-22.png")}
                  />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </section>

      <section className="bg-white top-bottom-padding two-colum-white">
        <Row justify={"center"}>
          <Col lg={19}>
            <Row
              justify={"center"}
              align={"middle"}
              gutter={[32, 32]}
              className="gutter-0"
            >
              <Col xs={24} sm={24} md={12}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-16.png")}
                  />
                </div>
              </Col>
              <Col xs={24} md={12}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("miniImg.png")}
                  />
                  <h4>
                    Sell Courses Directly On Your Platform Or Connect To An
                    Existing Store
                  </h4>
                  <p className="text-p-16">
                    Offer your courses for sale using a storefront created with
                    WrightCo Academy. If you already have an eCommerce site,
                    sell your courses there.
                  </p>
                  <List
                    itemLayout="horizontal"
                    dataSource={data4}
                    renderItem={(item, index) => (
                      <List.Item>
                        <List.Item.Meta
                          avatar={<FaCheckCircle />}
                          title={<p>{item.title}</p>}
                        />
                      </List.Item>
                    )}
                  />
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="greenTextButton"
                    onClick={() => navigate("/about-us")}
                    style={{ marginLeft: "5px" }}
                  >
                    See Pricing
                  </Button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>

      <section className="bg-gray top-bottom-padding">
        <Row justify={"center"} style={{ textAlign: "center" }}>
          <Col xl={10} lg={12}>
            <Image
              preview={false}
              alt={"Failed to load image"}
              src={ImageUrl("miniImg4.png")}
            />
            <h4 className="text-38">Customize The Shopping Experience</h4>
            <h4 className="text-38" style={{ color: "#00A79D" }}>
              For Your Learners
            </h4>
            <p className="text-p-16">
              Ensure your customers have a great experience purchasing courses,
              by customizing your website with different colors, menus, pages,
              and more.
            </p>
          </Col>
        </Row>
        <Row justify={"center"}>
          <Col xl={20} lg={18} md={23} xs={24}>
            <Row
              justify={"center"}
              align={"middle"}
              gutter={[16, 16]}
              className="gutter-0"
            >
              <Col xs={24} sm={24} md={8}>
                <div className="multiple-choice-card">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-23.png")}
                  />
                  <h5>Categorize Courses by Training Type</h5>
                  <p>
                    Categorize your courses to help customers find training
                    courses by sector, job role, and more.
                  </p>
                </div>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <div className="multiple-choice-card">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-24.png")}
                  />
                  <h5>Visually Brand Your Website</h5>
                  <p>
                    When selling courses directly on our software, you can use
                    your logo and brand colors throughout the site.
                  </p>
                </div>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <div className="multiple-choice-card">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-25.png")}
                  />
                  <h5>Create Unique Pages & Menus</h5>
                  <p>
                    Customize your eLearning sites’ landing pages, navigation
                    menus, and more with our site-builder.
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>

      <section className="bg-green top-bottom-padding">
        <Row justify={"center"} style={{ textAlign: "center" }}>
          <Col xl={10} lg={12}>
            <Image
              preview={false}
              alt={"Failed to load image"}
              src={ImageUrl("miniImg2.png")}
            />
            <h4 className="text-38 text-white">
              Connect With Payment Gateways
            </h4>
            <p className="text-p-16 text-white">
              Connect with Stripe, Authorize.net, and Braintree by PayPal to
              accept course payments. Bank-grade SSL security certificates make
              sure that your customers’ data is fully encrypted.
            </p>
            <Button
              type="primary"
              htmlType="submit"
              className="whiteButton"
              onClick={() => navigate("/about-us")}
              style={{ marginLeft: "5px" }}
            >
              Get Started
            </Button>
          </Col>
        </Row>
        <Row justify={"center"} style={{ padding: "10px 0" }}>
          <Col lg={20}>
            <Row
              justify={"center"}
              align={"middle"}
              gutter={[32, 32]}
              className="gutter-0"
            >
              <Col xs={24} sm={24} md={22}>
                <div className="" style={{ textAlign: "center" }}>
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-26.png")}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>

      <section className="bg-white top-bottom-padding two-colum-white">
        <Row justify={"center"}>
          <Col lg={19}>
            <Row
              justify={"center"}
              align={"middle"}
              gutter={[32, 32]}
              className="gutter-0"
            >
              <Col xs={24} md={12}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("miniImg4.png")}
                  />
                  <h4>Help Customers Find Training & Purchase Courses</h4>
                  <p className="text-p-16">
                    Use different pages, descriptions, and categories to help
                    customers find the right training for them. Use an About Us
                    page to showcase your company, Course Catalog for
                    advertising courses, and then a Check Out page to sell.
                  </p>
                  <List
                    itemLayout="horizontal"
                    dataSource={data3}
                    renderItem={(item, index) => (
                      <List.Item>
                        <List.Item.Meta
                          avatar={<FaCheckCircle />}
                          title={<p>{item.title}</p>}
                        />
                      </List.Item>
                    )}
                  />
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="greenTextButton"
                    onClick={() => navigate("/about-us")}
                    style={{ marginLeft: "5px" }}
                  >
                    See Pricing
                  </Button>
                </div>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-27.png")}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>

      <section className="bg-gray top-bottom-padding two-colum-white">
        <Row justify={"center"}>
          <Col lg={19}>
            <Row
              justify={"center"}
              align={"middle"}
              gutter={[32, 32]}
              className="gutter-0"
            >
              <Col xs={24} sm={24} md={12}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-28.png")}
                  />
                </div>
              </Col>
              <Col xs={24} md={12}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("miniImg4.png")}
                  />
                  <h4>Sell Courses In Bulk—To Other Teams & Customer Groups</h4>
                  <p className="text-p-16">
                    Sell courses in bulk to other organizations. Purchasers can
                    select the quantity of users they will be training at the
                    checkout page, to purchase training in bulk for their teams
                    or a group of customers.
                  </p>
                  <List
                    itemLayout="horizontal"
                    dataSource={data2}
                    renderItem={(item, index) => (
                      <List.Item>
                        <List.Item.Meta
                          avatar={<FaCheckCircle />}
                          title={<p>{item.title}</p>}
                        />
                      </List.Item>
                    )}
                  />
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="greenTextButton"
                    onClick={() => navigate("/about-us")}
                    style={{ marginLeft: "5px" }}
                  >
                    See Pricing
                  </Button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>

      <section className="bg-green top-bottom-padding">
        <Row justify={"center"} style={{ textAlign: "center" }}>
          <Col xl={10} lg={12}>
            <Image
              preview={false}
              alt={"Failed to load image"}
              src={ImageUrl("miniImg2.png")}
            />
            <h4 className="text-38 text-white">
              Discount eLearning with Coupons and Sales
            </h4>
            <p className="text-p-16 text-white">
              Discount your courses with coupon codes and put your courses on
              sale temporarily.
            </p>
            <Button
              type="primary"
              htmlType="submit"
              className="whiteButton"
              onClick={() => navigate("/about-us")}
              style={{ marginLeft: "5px" }}
            >
              Get Started
            </Button>
          </Col>
        </Row>
        <Row justify={"center"} style={{ padding: "10px 0" }}>
          <Col lg={20}>
            <Row
              justify={"center"}
              align={"middle"}
              gutter={[32, 32]}
              className="gutter-0"
            >
              <Col xs={24} sm={24} md={22}>
                <div
                  className=""
                  style={{ textAlign: "center", padding: "18px 0" }}
                >
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-29.png")}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>

      <section className="bg-gray top-bottom-padding two-colum-white">
        <Row justify={"center"}>
          <Col lg={19}>
            <Row
              justify={"center"}
              align={"middle"}
              gutter={[32, 32]}
              className="gutter-0"
            >
              <Col xs={24} md={12}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("miniImg.png")}
                  />
                  <h4>Offer Flexible Options For Purchasing Courses</h4>
                  <p className="text-p-16">
                    Sell courses with a one-time payment or subscription plan,
                    offer free trials, and bundle related courses together.
                  </p>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="greenTextButton"
                    onClick={() => navigate("/about-us")}
                    style={{ marginLeft: "5px" }}
                  >
                    See Pricing
                  </Button>
                </div>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-30.png")}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>

      <section className="bg-white top-bottom-padding">
        <Row justify={"center"}>
          <Col xl={18} lg={17} md={23} xs={23}>
            <div className="on-demand">
              <h4>Efficiently Sell Courses & Webinars Online</h4>
              <Button
                type="primary"
                htmlType="submit"
                className="whiteButton"
                onClick={() => navigate("/about-us")}
                style={{ marginLeft: "5px" }}
              >
                Get Started
              </Button>
            </div>
          </Col>
        </Row>
      </section>
    </Layout>
  );
}

export default Homepage;
