// export const BASE_URL = "https://secure.demo243.webhostlabs.net:3002/api/"
// export const UPLOADS_URL = "https://secure.demo243.webhostlabs.net:3002/Uploads/"

// import ChangePassword from "../../views/change-password"

const { NODE_ENV } = process.env;
const { hostname } = window.location;

const servers = {
  local: "http://localhost:3012",
  customDev: "https://react.customdev.solutions:3012",
  live: "https://wrightcoacademy.com:3012",
};

var URL;
if (NODE_ENV === "production" && hostname.includes("react.customdev.solutions"))
  URL = servers.customDev;
else if (NODE_ENV === "production" && hostname.includes("wrightcoacademy.com") ) URL = servers.live;
else URL = servers.local;

export const BASE_URL = `${URL}/api`;
export const UPLOADS_URL = `${URL}/Uploads/`;
export const UPLOAD_URL2 = `${URL}/`;
export const SOCKET_URL = `${URL}`;

// export const BASE_URL = "https://react.customdev.solutions:3012/api"
// export const UPLOADS_URL = "https://react.customdev.solutions:3012/Uploads"
// export const SOCKET_URL = "https://react.customdev.solutions:3012"

// export const BASE_URL = "http://localhost:3012/api"
// export const UPLOADS_URL = "http://localhost:3012/Uploads"
// export const SOCKET_URL = "http://localhost:3012"

export const AUTH = {
  signin: "/auth/signin",
  signup: "/auth/signup",
  emailCode: "/auth/emailVerificationCode",
  verifyCode: "/auth/verifyRecoverCode",
  resetPassword: "/auth/resetPassword",
};
export const FEEDBACK = {
  addFeedback: "/feedback/addFeedback",
}
export const CATEGORIES = {
  getAllcategories: "/category/getAllcategories",
  addCategory: "/category/addCategory",
  updateCategory: "/category/updateCategory/",
  toggleStatus: "/category/toggleStatus/",
  edit: "/category/edit/",
};

export const STUDENT = {
  updateProfile: "/profile/updateProfile",
  changePassword: "/profile/changePassword",
  getMyCoaches: "/profile/getMyCoaches",
};

export const USERS = {
  get: "/auth/signin",
  getAllCoaches: "/admin/user/getAllCoaches",
  getAllTutors: "/admin/user/getAllTutors",
  getCoachById: "/admin/user/getCoachById/",
};

export const COACH = {
  updateProfile: "/coach/profile/updateProfile",
  changePassword: "/coach/profile/changePassword",
};

export const SERVICES = {
  getAll: "/service/getAllServices",
};

export const RATES = {
  getMyRates: "/rates/getMyRates",
  setRates: "/rates/setRates",
};

export const COMISSSION = {
  getComission: "/comission/getComission",
};

export const COURSE = {
  addCourse: "/course/addCourse",
  getAllCourses: "/course/getAllCourses",
  getMyCourses: "/course/getMyCourses",
  getCourseById: "/course/getCourseById/",
  updateCourse: "/course/updateCourse/",
};

export const LECTURE = {
  getAllLecturesByCourse: "/lecture/getAllLecturesByCourse/",
  getLectureById: "/lecture/getLectureById/",
};

export const SCHEDULE = {
  addSchedule: "/schedule/addSchedule",
  getMySchedule: "/schedule/getMySchedule",
  getScheduleByCoachId: "/schedule/getScheduleByCoachId/",
};

export const CHAT = {
  createChat: "/chat/createChat",
  getMyChats: "/chat/getMyChats",
};

export const MESSAGE = {
  createMessage: "/message/createMessage",
  getChatMessages: "/message/getChatMessages/",
};
export const MEETING = {
  addMeeting: "/meeting/addMeeting",
  getAllMeetings: "/meeting/getAllMeetings",
  getAllCoachMeetings: "/meeting/getAllCoachMeetings/",
  getMyMeetings: "/meeting/getMyMeetings",
  getMeetingById: "/meeting/getMeetingById/",
  updateMeeting: "/meeting/updateMeeting/",
};
export const ASSESMENT = {
  addAssesment: "/assesment/addAssesment",
  getAllAssesments: "/assesment/getAllAssesments",
  getAllCoachAssesment: "/assesment/getAllCoachAssesment/",
  getMyAssesments: "/assesment/getMyAssesments",
  getAssesmentById: "/assesment/getAssesmentById/",
  updateAssesment: "/assesment/updateAssesment/",
  deleteAssesment: "/assesment/updateAssesment/",
};

export const REVIEWS = {
  getAll: "/review/getAllReviewsByCoachId/",
  addReview: "/review/addReview",
  getCoachRatings: "/review/getCoachRatings/",
};

export const REQUEST = {
  addRequest: "/request/addRequest",
};

export const LESSON = {
  bookLesson: "/lesson/addLesson",
  getUpcomingLessons: "/lesson/getAllUpcomingLessons",
  getCompletedLessons: "/lesson/getAllCompletedLessons",
  getLiveLessons: "/lesson/getAllLiveLessons",
  getLessonById: "/lesson/getLessonById/",
};

export const PAYMENT = {
  lessonPayment: "/payment/lessonPayment",
  coursePayment: "/payment/coursePayment",
  meetingPayment: "/payment/meetingPayment",
};

export const NOTIFICATION = {
  getAllMyNotifications: "/notification/getAllMyNotifications",
  getUnreadNotifications: "/notification/getUnreadNotifications",
  toggleNotification: "/notification/toggleNotification/",
};

export const QUERY = {
  addQuery: "/query/addQuery",
};

export const QUIZ = {
  getMyQuiz: "/quiz/getMyQuiz",
  getQuizById: "/quiz/getQuizById/",
  attemptQuiz: "/quiz/attemptQuiz/",
};
