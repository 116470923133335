import React, { useEffect, useRef, useState } from "react";
import { Card, Image, Row, Table } from "antd";
import HTMLFlipBook from "react-pageflip";
import { ImageUrl } from "../../helpers/Function";
import { AiOutlineEye } from "react-icons/ai";

const { Meta } = Card;

const PageCover = React.forwardRef((props, ref) => {
  return (
    <div className="page page-cover" ref={ref} data-density="hard">
      <div className="page-content">
        <h2>{props.children}</h2>
      </div>
    </div>
  );
});

const Page = React.forwardRef((props, ref) => {
  return (
    <div className="page" ref={ref}>
      <div className="page-content">
        <div className="page-image"></div>
        <div className="page-text">{props.children}</div>
      </div>
    </div>
  );
});

function DemoBook({ users, result }) {
  const [page, setPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0);

  const flipBook = useRef(); // Ref for HTMLFlipBook

  const nextButtonClick = () => {
    flipBook.current.pageFlip().flipNext(); // Flip to next page
  };

  const prevButtonClick = () => {
    flipBook.current.pageFlip().flipPrev(); // Flip to previous page
  };

  const onPage = (e) => {
    setPage(e.data);
  };

  const onInit = () => {
    const pageCount = flipBook.current.pageFlip().getPageCount();
    setTotalPage(pageCount);
  };

  const columns = [
    {
      title: "Tutor Name",
      dataIndex: "tutorName",
      key: "tutorName",
    },
    {
      title: "Joining Date",
      dataIndex: "joiningDate",
      key: "joiningDate",
    },
    {
      title: "Invitation",
      dataIndex: "invitation",
      key: "invitation",
    },
    {
      title: "Assignment",
      dataIndex: "assignment",
      key: "assignment",
    },
    {
      title: "Action",
      dataIndex: "_id",
      key: "_id",
      render: (item) => (
        <AiOutlineEye
          style={{ fontSize: "18px", color: "grey", cursor: "pointer" }}
        />
      ),
    },
  ];

  const columns2 = [
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Quiz",
      dataIndex: "quiz",
      key: "quiz",
    },
    {
      title: "Classes",
      dataIndex: "classes",
      key: "classes",
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
    },
    {
      title: "Obtain",
      dataIndex: "obtain",
      key: "obtain",
    },
    {
      title: "Grade",
      dataIndex: "grade",
      key: "grade",
    },
  ];

  return (
    <div>
      <HTMLFlipBook
        width={550}
        height={790}
        size="fixed"
        minWidth={315}
        maxWidth={700}
        minHeight={400}
        maxHeight={500}
        useMouseEvents={false}
        maxShadowOpacity={0.1}
        showCover={false}
        mobileScrollSupport={true}
        onFlip={onPage}
        onInit={onInit}
        className="demo-book"
        ref={flipBook} // Attach ref
      >
        <PageCover>
          <Image
            preview={false}
            alt={"Failed to load image"}
            src={ImageUrl("pageonebg.png")}
          />
        </PageCover>
        <Page number={1}>
          <Image
            preview={false}
            alt={"Failed to load image"}
            src={ImageUrl("bookheader.png")}
          />
          <div className="page-box">
            <h3>Over View</h3>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and it to make a type specimen book. <br /> <br /> There are
              many variations of passages of Lorem Ipsum available, but the
              majority have suffered alteration in some form, by injected
              humour, or randomised words which don't look even slightly
              believable. If you are going to use a passage of Lorem Ipsum, you
              need to be sure there isn't anything embarrassing hidden in the
              middle of text. All the Lorem Ipsum generators on the Internet
              tend to repeat predefined chunks as necessary, making this the
              first true generator on the Internet. It uses a dictionary of over
              200 Latin words, combin a handful of model sentence structures, to
              generate Lorem Ipsum which looks reasonable. The generated Lorem
              Ipsum is therefore always free from repetition, injected humour,
              or non-characteristic words etc.
            </p>
            <div className="book-cards">
              <div>
                <Image
                  preview={false}
                  alt={"Failed to load image"}
                  src={ImageUrl("bookicon.png")}
                />
                <h6>There are of passages words etc.</h6>
              </div>
              <div>
                <Image
                  preview={false}
                  alt={"Failed to load image"}
                  src={ImageUrl("bookbulb.png")}
                />
                <h6>There are of passages words etc.</h6>
              </div>
              <div>
                <Image
                  preview={false}
                  alt={"Failed to load image"}
                  src={ImageUrl("bookarrow.png")}
                />
                <h6>There are of passages words etc.</h6>
              </div>
            </div>
            <h3>Report</h3>
              <p style={{ paddingBottom: "10px" }}>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and it to make a type specimen book.
              </p>
              <Image
                preview={false}
                alt={"Failed to load image"}
                src={ImageUrl("bookchart.png")}
              />
          </div>
        </Page>
        <Page number={2}>
          <Image
            preview={false}
            alt={"Failed to load image"}
            src={ImageUrl("bookheader.png")}
          />
          <div className="page-box">
            <h3>Evaluation Report</h3>
            <Image
              preview={false}
              alt={"Failed to load image"}
              src={ImageUrl("bookchart2.png")}
            />
            <p>
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form, by
                injected humour, or randomised words which don't look even
                slightly believable. If you are going to use a passage of Lorem
                Ipsum, you need to be sure there isn't anything embarrassing
                hidden in the middle of text. All the Lorem Ipsum generators on
                the Internet tend to repeat chunks as necessary, making this the
                first true generator on the Internet. It uses a dictionary of
                over 200 Latin words, combin a handful of model sentence
                structures, to generate Lorem Ipsum which looks reasonable. The
                generated Lorem Ipsum is therefore always free from repetition,
                injected humour, or non-characteristic words etc.
                <br /> <br /> There are many variations of passages of Lorem
                Ipsum available, but the majority have suffered alteration in
                some form, by injected humour, or randomised words which don't
                look even slightly believable. If you are going to use a passage
                of Lorem Ipsum, you need to be sure there isn't anything
                embarrassing hidden in the middle of text. All the Lorem Ipsum
                generat.
              </p>
          </div>
        </Page>
        <Page number={3}>
          <Image
            preview={false}
            alt={"Failed to load image"}
            src={ImageUrl("bookheader.png")}
          />
          <div className="page-box">
            <h3>Trainer and Satisfaction</h3>
            <Row style={{ padding: 20, overflow: "auto" }}>
              <Table
                className="styledTable"
                dataSource={users}
                columns={columns}
                pagination={false}
              />
            </Row>
          </div>
        </Page>
        <Page number={4}>
          <Image
            preview={false}
            alt={"Failed to load image"}
            src={ImageUrl("bookheader.png")}
          />
          <div className="page-box">
          <h3>Trainer Details</h3>
              <div className="tutor-Details">
                <div>
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("tutorpic.png")}
                  />
                </div>
                <div>
                  <h6>Sheldon</h6>
                  <p>2839 Carlisle Blvd NE Albuquerque New York 87110</p>
                </div>
              </div>
              <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combin a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.</p>
            <Row style={{ padding: 20, overflow: "auto" }}>
              <Table
                className="styledTable"
                dataSource={result}
                columns={columns2}
                pagination={false}
              />
            </Row>
          </div>
        </Page>
        <PageCover>
          <Image
            preview={false}
            alt={"Failed to load image"}
            src={ImageUrl("pageonebg.png")}
          />
        </PageCover>
      </HTMLFlipBook>

      <div className="container next-pre">
        <div>
          <button
            type="button"
            className="greenTextButton"
            onClick={prevButtonClick}
          >
            Previous page
          </button>
          <button
            type="button"
            className="greenTextButton"
            onClick={nextButtonClick}
          >
            Next page
          </button>
        </div>
      </div>
    </div>
  );
}

function MyBook(props) {
  const [users, setUsers] = useState([
    {
      key: "1",
      tutorName: (
        <div className="tutorimg-box">
          <Image
            preview={false}
            alt={"Failed to load image"}
            src={ImageUrl("tutorimg.png")}
          />
          <div>
            <h6>Sheldon</h6>
            <p>trainer@gmail.com</p>
          </div>
        </div>
      ),
      joiningDate: "15 feb 2024",
      invitation: "Approved",
      assignment: "1/7",
    },
  ]);

  const [result, setResult] = useState([
    {
      key: "1",
      subject: "Math",
      date: "10 Apr 2024",
      quiz: "Quiz 1",
      classes: "12",
      total: "100",
      obtain: "90",
      grade: "A",
    },
  ]);

  return <DemoBook users={users} result={result} />;
}

export default MyBook;
