import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Col,
  Button,
  Row, Typography,
  Layout, Image,
  Card,
  Spin, Input,message, Radio, Space 
} from "antd";
import { useNavigate, useParams } from "react-router";
import { UPLOADS_URL, MEETING, QUIZ } from "../../config/constants/api";
import { Get } from "../../config/api/get";
import swal from "sweetalert";
import "react-alice-carousel/lib/alice-carousel.css";
import dayjs from "dayjs";
import { BsDot } from "react-icons/bs";
import { Post } from "../../config/api";

function QuizAttempt() {
  const {id} = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.userData);
  const token = useSelector((state) => state.user.userToken);
  const [paginationConfig, setPaginationConfig] = useState({
    pageNumber: 1,
    limit: 10,
    totalDocs: 0,
    totalPages: 0,
  });
  const [reviews,setReviews] = useState([])
  const [ratings,setRatings] = useState({})
  const [quiz,setQuiz]= useState({})
  const days = ["Sunday","Monday",'Tuesday',"Wednesday","Thursday","Friday","Saturday"]
  const { Search } = Input;
  const [loading, setLoading] = useState(true);
  const [meetings, setMeetings] = useState([]);
  const [range, setRange] = useState([10, 200]);
  const [review, setReview] = useState({
    averageRating: 0,
    totalReviews: 0
  });
  const [rates, setRates] = useState({
    hourlyRate: 0,
    tutoringRate: 0,
    meetingingRate: 0,
  });
  const [response, setResponse] = useState({})


  const item = { rating: 4 };

  useEffect(()=>{
    getQuizDetails();
    if(token){
      getMyMeetings()
    }

  },[])


  console.log("setMeetings",meetings)


  const getMyMeetings = async (pageNumber, keyword, category) => {
    setLoading(true);
    try {
      let response = await Get(MEETING.getMyMeetings,token, {
        limit: "100",
      });
      setLoading(false);
      console.log("response", response);
      if (response?.status) {
        setMeetings(response?.data?.meetings.map(item => item._id));
      } else {
        message.error("Something went wrong!");
        console.log("error====>", response);
      }
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  };

  const getQuizDetails = async () =>{
    try {
      let response = await Get(QUIZ.getQuizById + id, token);
      if (response?.status) {
        setQuiz(response?.data);
      } else {
        swal("Error", response?.message, "error");
       
      }
      setLoading(false);
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  }

  const submitQuiz = async () =>{
    try {
      let questionResponse = Object?.keys(response)?.map(val=>({question: val, answer: response[val]}))
      let obj = {
        responses: questionResponse
      }
      let res = await Post(QUIZ?.attemptQuiz + id, obj, token);
      if (res?.data?.status) {
        swal("Success!", "Quiz Submitted Successfully", "success");
        navigate("/my-quiz")
      } else {
        swal("Error", res?.data?.message, "error");
      }
      setLoading(false);
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  }


  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Row
        className="blueBackground"
        style={{
          height: "45vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Typography.Title
          className="fontFamily1"
          style={{
            fontSize: "30px",
            color: "#333d47",
            marginTop: 20,
            marginBottom: 20,
          }}
        >
          {<> Quiz Details</>}
        </Typography.Title>
      </Row>

      {/* section 2 */}
      <Row
        className="whiteBackground"
        style={{
          backgroundColor: "white",
          justifyContent: "center",
          padding: "50px",
        }}
        gutter={20}
      >
        <Col xs={24} md={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",

              padding: "10px",
            }}
          >
            <Card className="contactCard2" bordered={false}>
            {loading && <Row style={{minHeight:"30vh"}} className="flex">
              <Spin size="large" />
                </Row>}
             {!loading && <Row
                style={{
                  width: "100%",
                  padding: "20px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                {quiz?.quiz?.questions?.map(val=><Questions question={val} response={response} setResponse={setResponse}/>)}
              </Row>}
              <Row justify={"end"}>
                <Col>
                <Button
                  type="primary"
                  size="large"
                  block
                  style={{backgroundColor:"#00a79d", cursor: "pointer"}}
                  onClick={submitQuiz}
                  >
                Submit
                </Button>
                    </Col>
                </Row>
            </Card>
          </div>
        </Col>
        
       
      </Row>
    </Layout>
  );
}

export default QuizAttempt;

const Questions = ({question, response, setResponse}) => {
  const onChange = (e) => {
    let {value} = e?.target
    setResponse((prev)=>({...prev, [question?._id]: value}))
  };

  return (
    <>
      <Row style={{ backgroundColor: "#00A79D", padding: "0px 20px", marginTop: 20, width: '100%', color: '#fff' }}>
        <p>{question?.title}</p>
      </Row>
      <Radio.Group onChange={onChange} value={response[question?._id]} style={{padding: "15px 20px"}}>
        <Space direction="vertical">
          {question?.options?.map(val=><Radio value={val}>{val}</Radio>)}
        </Space>
      </Radio.Group>
    </>
  )
}
