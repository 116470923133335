import React from "react";
// import AuthLayout from "../../components/";
import {
  Col,
  Row,
  Typography,
  List,
  Form,
  Layout,
  Input,
  Button,
  Card,
  Tabs,
  Table,
  Image,
  Divider,
  Rate,
} from "antd";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { Post } from "../../config/api/post";
import { AUTH, UPLOADS_URL } from "../../config/constants/api";
import { addUser, removeUser } from "../../redux/slice/authSlice";
import { PiMedal } from "react-icons/pi";
import {
  FaArrowRight,
  FaCheck,
  FaCheckCircle,
  FaMedal,
  FaRegClock,
} from "react-icons/fa";
import { TbBolt } from "react-icons/tb";
import { LiaBrainSolid } from "react-icons/lia";
import swal from "sweetalert";
import { ImageUrl } from "../../helpers/Function";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoIosMail } from "react-icons/io";
const { Meta } = Card;
// import router from "next/router";

function Homepage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { Search } = Input;
  const user = useSelector((state) => state.user.userData);
  const token = useSelector((state) => state.user.userToken);
  const [loading, setLoading] = React.useState(false);

  // useEffect if user is already logged in
  React.useEffect(() => {
    if (user && token) {
      navigate("/", { replace: true });
    }
  }, [user, token]);

  const onFinish = (values) => {
    console.log("Success:", values);
    setLoading(true);

    let data = {
      email: values.email,
      password: values.password,
      devideId: "123456789",
    };
    Post(AUTH.signin, data)
      .then((response) => {
        setLoading(false);
        if (response?.data) {
          console.log("response", response.data.token);
          console.log("response", response.data.user);
          dispatch(
            addUser({ user: response.data.user, token: response.data.token })
          );
          navigate("/", { replace: true });
        } else {
          swal("Oops!", response.response.data.message, "error");
        }
      })
      .catch((e) => {
        console.log(":::;", e);
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const data1 = [
    {
      title: "Attach a logo to the portal.",
    },
    {
      title: "Create a referral code for users to self-enroll into a portal.",
    },
  ];

  const data2 = [
    {
      title: "Provide course access to users directly after purchase.",
    },
  ];

  const data3 = [
    {
      title: "Management Team",
    },
    {
      title: "Facilities",
    },
    {
      title: "Education",
    },
    {
      title: "Parent Training",
    },
    {
      title: "Social Services",
    },
    {
      title: "Nutrition",
    },
    {
      title: "Board Development",
    },
  ];

  return (
    <Layout style={{ minHeight: "100vh", backgroundSize: "cover" }}>
      <section>
        <Row className="cource-banner" justify={"center"} align={"middle"}>
          <Col xs={24} md={10}>
            <div className="">
              <Row style={{ width: "100%", justifyContent: "center" }}>
                <Col xs={20} md={20} className="formWrap">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("miniImg3.png")}
                  />
                  <p className="text-p-14">B2B Customer Portals</p>
                  <Typography.Title
                    className="fontFamily1 smallTitle"
                    style={{ color: "#fff" }}
                  >
                    {<> Train Multiple Audiences With WrightCo Academy</>}
                  </Typography.Title>

                  <Typography.Text
                    className="fontFamily1 smallText"
                    style={{ color: "#fff" }}
                  >
                    Onboard external customers into their own training portals.
                    Customers can enroll users in courses and efficiently manage
                    training in their portal.
                  </Typography.Text>
                  <br />
                  <br />
                  <br />
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="whiteButton"
                    onClick={() => navigate("/about-us")}
                    style={{ marginLeft: "5px" }}
                  >
                    Get Started
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="loginButton"
                    onClick={() => navigate("/about-us")}
                  >
                    See The Platform <FaArrowRight />
                  </Button>
                </Col>
              </Row>
            </div>
          </Col>

          <Col xs={0} sm={0} md={12}>
            <div className="" style={{ textAlign: "center" }}>
              <Row style={{ width: "100%", paddingRight: "20px" }} gutter={40}>
                <Col xs={0} sm={12} md={24}>
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-31.png")}
                  />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </section>

      <section className="bg-white top-bottom-padding two-colum-white">
        <Row justify={"center"}>
          <Col lg={19}>
            <Row
              justify={"center"}
              align={"middle"}
              gutter={[32, 32]}
              className="gutter-0"
            >
              <Col xs={24} sm={24} md={12}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-32.png")}
                  />
                </div>
              </Col>
              <Col xs={24} md={12}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("miniImg4.png")}
                  />
                  <h4>Assign each of your Customers to a Portal</h4>
                  <p className="text-p-16">
                    Each group—e.g. a company or group of customers—gets its own
                    branded portal that admins can use to manage training, and
                    learners can use it to take courses.
                  </p>
                  <List
                    itemLayout="horizontal"
                    dataSource={data1}
                    renderItem={(item, index) => (
                      <List.Item>
                        <List.Item.Meta
                          avatar={<FaCheckCircle />}
                          title={<p>{item.title}</p>}
                        />
                      </List.Item>
                    )}
                  />
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="greenTextButton"
                    onClick={() => navigate("/about-us")}
                    style={{ marginLeft: "5px" }}
                  >
                    See Pricing
                  </Button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>

      <section className="bg-green top-bottom-padding">
        <Row justify={"center"} style={{ textAlign: "center" }}>
          <Col xl={10} lg={12}>
            <Image
              preview={false}
              alt={"Failed to load image"}
              src={ImageUrl("miniImg2.png")}
            />
            <h4 className="text-38 text-white">
              Each Customer gets their own Reporting Dashboard
            </h4>
            <p className="text-p-16 text-white">
              Each portal has an assigned group admin (somebody from your
              customer’s organization) who is in charge of managing user
              activity with their own dashboard. Portals can have more than 1
              admin.
            </p>
            <Button
              type="primary"
              htmlType="submit"
              className="whiteButton"
              onClick={() => navigate("/about-us")}
              style={{ marginLeft: "5px" }}
            >
              Get Started
            </Button>
          </Col>
        </Row>
        <Row justify={"center"} style={{ padding: "10px 0" }}>
          <Col lg={20}>
            <Row
              justify={"center"}
              align={"middle"}
              gutter={[32, 32]}
              className="gutter-0"
            >
              <Col xs={24} sm={24} md={22}>
                <div className="" style={{ textAlign: "center" }}>
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-33.png")}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>

      <section className="bg-gray top-bottom-padding">
        <Row justify={"center"} style={{ textAlign: "center" }}>
          <Col xl={10} lg={12}>
            <Image
              preview={false}
              alt={"Failed to load image"}
              src={ImageUrl("miniImg4.png")}
            />
            <h4 className="text-38">
              Track your Customers’ Progress with Learner Reports
            </h4>
            <p className="text-p-16">
              After onboarding a customer, administrators can keep track of
              their users’ progress and engagement using different reports.
            </p>
          </Col>
        </Row>
        <Row justify={"center"}>
          <Col xl={20} lg={18} md={23} xs={24}>
            <Row
              justify={"center"}
              align={"middle"}
              gutter={[16, 16]}
              className="gutter-0"
            >
              <Col xs={24} sm={24} md={8}>
                <div className="multiple-choice-card">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-34.png")}
                  />
                  <h5>Review Assignments and Give Feedback</h5>
                  <p>
                    Review assignment submissions, provide feedback, and give
                    grades directly inside of the LMS.
                  </p>
                  <span onClick={() => navigate("/")}>See Pricing</span>
                </div>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <div className="multiple-choice-card">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-35.png")}
                  />
                  <h5>Track Completions - Scores - Time-Spent</h5>
                  <p>
                    When selling courses directly on our software, you can use
                    your logo and brand colors throughout the site.
                  </p>
                  <span onClick={() => navigate("/")}>See Pricing</span>
                </div>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <div className="multiple-choice-card">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-36.png")}
                  />
                  <h5>Keep Track Of Learner Progress</h5>
                  <p>
                    Customers can report on learner progress in their portal by
                    looking at an individual or a group of learners.
                  </p>
                  <span onClick={() => navigate("/")}>See Pricing</span>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>

      <section className="bg-green top-bottom-padding two-colum-white">
        <Row justify={"center"}>
          <Col lg={20}>
            <Row justify={"center"} align={"middle"}>
              <Col xs={24} md={12}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("miniImg2.png")}
                  />
                  <h4 className="text-white">
                    Quickly Enroll Users in Courses
                  </h4>
                  <p className="text-p-16 text-white">
                    While onboarding a customer, you can enroll thousands of
                    users at a time into courses, or your customers can enroll
                    users themselves. Group admins can purchase new training
                    courses or additional licenses to existing courses anytime.
                  </p>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="trsnpButton"
                    onClick={() => navigate("/about-us")}
                    style={{ marginLeft: "5px" }}
                  >
                    See Pricing
                  </Button>
                </div>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-37.png")}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>

      <section className="bg-gray  top-bottom-padding two-colum-white">
        <Row justify={"center"}>
          <Col lg={19}>
            <Row
              justify={"center"}
              align={"middle"}
              gutter={[32, 32]}
              className="gutter-0"
            >
              <Col xs={24} md={12}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("miniImg4.png")}
                  />
                  <h4>Organize Internal Training</h4>
                  <p className="text-p-16">
                    Organize your internal training by creating separate portals
                    for each department. Training portals can be managed by team
                    leaders.
                  </p>
                  <List
                    itemLayout="horizontal"
                    dataSource={data3}
                    renderItem={(item, index) => (
                      <List.Item>
                        <List.Item.Meta
                          avatar={<FaCheckCircle />}
                          title={<p>{item.title}</p>}
                        />
                      </List.Item>
                    )}
                  />
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="greenTextButton"
                    onClick={() => navigate("/about-us")}
                    style={{ marginLeft: "5px" }}
                  >
                    See Pricing
                  </Button>
                </div>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <div className="">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={ImageUrl("new-38.png")}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>

      <section className="bg-white top-bottom-padding">
        <Row justify={"center"}>
          <Col xl={18} lg={17} md={23} xs={23}>
            <div className="on-demand">
              <h4>Efficiently Sell Courses & Webinars Online</h4>
              <Button
                type="primary"
                htmlType="submit"
                className="whiteButton"
                onClick={() => navigate("/about-us")}
                style={{ marginLeft: "5px" }}
              >
                Get Started
              </Button>
            </div>
          </Col>
        </Row>
      </section>
    </Layout>
  );
}

export default Homepage;
